import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mt-n3",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"ml-n3 mr-n3"},[_vm._v(" Accusation list "),_c(VSpacer),(_vm.isEdit && !_vm.isRespond)?_c(VBtn,{staticClass:"primary text-capitalize",on:{"click":function($event){return _vm.addLine()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Accusation ")],1):_vm._e()],1),_c(VDivider),_c(VDataTable,{staticClass:"elevation-0",attrs:{"items":_vm.lines,"headers":_vm.lineHeaders,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c(VAutocomplete,{staticClass:"mt-4",attrs:{"items":_vm.categories,"item-text":function (item) { return item.description; },"item-value":function (item) { return item.code; },"readonly":!_vm.isEdit || _vm.isRespond,"label":"Disciplinary Category","outlined":"","clearable":""},model:{value:(item.categoryCode),callback:function ($$v) {_vm.$set(item, "categoryCode", $$v)},expression:"item.categoryCode"}})]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c(VTextarea,{staticClass:"mt-4",attrs:{"label":"Accusation Details","rows":"2","dense":"","readonly":!_vm.isEdit || _vm.isRespond,"outlined":"","clearable":""},model:{value:(item.details),callback:function ($$v) {_vm.$set(item, "details", $$v)},expression:"item.details"}})]}},{key:"item.response",fn:function(ref){
var item = ref.item;
return [_c(VTextarea,{staticClass:"mt-4",attrs:{"label":"Accusation Response","rows":"2","dense":"","outlined":"","readonly":_vm.user.employee !== _vm.disciplinary.accused,"placeholder":"Enter your response to the accusation","persistent-placeholder":"","clearable":""},model:{value:(item.response),callback:function ($$v) {_vm.$set(item, "response", $$v)},expression:"item.response"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isEdit && !_vm.isRespond)?_c(VBtn,{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeLine(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }