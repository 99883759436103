<template>
  <v-card flat class="mx-auto mt-n3">
    <v-card-title class="ml-n3 mr-n3">
      Accusation list
      <v-spacer />
      <v-btn
        class="primary text-capitalize"
        @click="addLine()"
        v-if="isEdit && !isRespond"
      >
        <v-icon left>mdi-plus</v-icon>
        Add Accusation
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-data-table
      :items="lines"
      :headers="lineHeaders"
      class="elevation-0"
      disable-pagination
      hide-default-footer
    >
      <!--eslint-disable-next-line -->
      <template v-slot:item.category="{ item }">
        <v-autocomplete
          :items="categories"
          :item-text="(item) => item.description"
          :item-value="(item) => item.code"
          v-model="item.categoryCode"
          class="mt-4"
          :readonly="!isEdit || isRespond"
          label="Disciplinary Category"
          outlined
          clearable
        />
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.details="{ item }">
        <v-textarea
          v-model="item.details"
          label="Accusation Details"
          class="mt-4"
          rows="2"
          dense
          :readonly="!isEdit || isRespond"
          outlined
          clearable
        />
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.response="{ item }">
        <v-textarea
          v-model="item.response"
          label="Accusation Response"
          class="mt-4"
          rows="2"
          dense
          outlined
          :readonly="user.employee !== disciplinary.accused"
          placeholder="Enter your response to the accusation"
          persistent-placeholder
          clearable
        />
      </template>
      <!--eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          color="error"
          @click="removeLine(item)"
          v-if="isEdit && !isRespond"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  name: "disciplinaryLines",
  props: {
    disciplinaryLine: {
      type: Array,
      default: () => [],
    },
    disciplinary: {
      type: Object,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    isRespond: {
      type: Boolean,
      default: false,
    },
    user: Object,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    lines: {
      get() {
        return this.disciplinaryLine;
      },
      set(value) {
        this.$emit("update:disciplinaryLine", value);
      },
    },
    categories() {
      return this.$store.getters["Disc/discGetters"]("disciplinaryCategories");
    },
    lineHeaders() {
      return [
        {
          text: "Category",
          value: "category",
        },
        {
          text: "details",
          value: "details",
        },
        {
          text: "response",
          value: "response",
        },
        {
          text: "actions",
          value: "actions",
        },
      ].filter((el) => {
        if (!this.isRespond && this.isEdit) {
          return el.value !== "response";
        } else {
          return true;
        }
      });
    },
  },
  methods: {
    addLine() {
      this.lines.push({
        category: null,
        details: null,
      });
    },
    removeLine(line) {
      if (line.lineNo) {
        this.$confirm.show({
          title: "Delete Line",
          text: "Are you sure you want to delete this line?",
          onConfirm: () => {
            this.$store.dispatch("Disc/deleteDisciplinaryLine", { ...line });
          },
        });
      } else {
        this.lines.splice(this.lines.indexOf(line), 1);
      }
    },
  },
};
</script>
