<template>
  <v-card flat>
    <v-card-title class="header-title ml-3"> Approval Entries </v-card-title>
    <v-card-text>
      <v-timeline dense>
        <v-timeline-item
          v-for="(item, i) in sortApprovalEntries"
          :key="i"
          :color="getColor(item.status).icon_color"
          small
          fill-dot
          v-show="item.status !== 'Canceled'"
          :icon="getColor(item.status).icon"
        >
          <v-row v-if="item.status !== 'Canceled'">
            <v-col cols="4">
              <v-list-item>
                <v-list-item-avatar
                  :color="getColor(item.status).icon_color"
                  style="color: rgb(0, 68, 139)"
                  size="50"
                >
                  {{
                    splitApproverID(getEmployeeByID(item.approverID).firstName)
                  }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="view-page font-weight-bold">
                    {{
                      getEmployeeByID(item.approverID).firstName +
                      " " +
                      getEmployeeByID(item.approverID).lastName
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ getEmployeeByID(item.approverID).email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <div class="d-flex justify-start">
                <v-icon>schedule</v-icon>
                <strong class="view-page">{{
                  getEntryDateFormat(item.dueDate)
                }}</strong>
              </div>
              <v-divider />
              <div class="d-flex justify-start">
                <div class="">
                  <h4 class="view-page">Status:</h4>
                </div>
                <v-chip
                  label
                  class="mx-1 my-1"
                  :color="getColor(item.status).icon_color"
                  x-small
                  ><span class="view-page">{{ item.status }}</span>
                </v-chip>
              </div>
            </v-col>
            <v-col cols="4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="view-page font-weight-bold">
                    Comment
                  </v-list-item-title>
                  <v-card flat class="mt-1 text--secondary">
                    {{ getComment(item.approvalComments) }}
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";
export default {
  name: "ApprovalEntries",
  props: { approvalEntries: Array },
  computed: {
    sortApprovalEntries() {
      return [...this.approvalEntries].sort((a, b) => {
        return new Date(b.dueDate) - new Date(a.dueDate);
      });
    },
  },
  methods: {
    getEmployeeByID(id) {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees")
        .filter((el) => el.number === id)
        .shift();
    },
    getColor(status) {
      return helper.getColor(status);
    },
    getEntryDateFormat(date) {
      return helper.getEntryDateFormat(date);
    },
    splitApproverID(id) {
      return id[0] + id[1];
    },
    getComment(comment) {
      return comment[0] ? comment[0].comment : "";
    },
  },
};
</script>
