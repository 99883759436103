<template>
  <v-container v-if="application !== undefined">
    <v-dialog
      persistent
      overlay-opacity="0.5"
      v-model="approvalModal.open"
      width="40%"
    >
      <v-card class="mx-auto" tile>
        <v-btn
          icon
          :style="{
            float: 'right',
            color: '#000',
            marginTop: '1%',
          }"
          style="position: absoulte; right: 1em"
          @click="approvalModal = false"
        >
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          {{ !approvalModal.status ? "Reject" : "Approve" }}
          <!-- appcode -->
        </v-card-title>
        <v-card-text>
          <v-form rel="approveForm" v-model="isValid">
            <div v-if="approvalModal.status">Confrim Approval</div>
            <div v-else>
              <div>Confrim Reject</div>
              <v-textarea
                placeholder="Comments"
                :rows="1"
                v-model="comments"
                clearable
                class="mt-1 mx-4 leaveInputs"
                filled
                :rules="[rules.common.required]"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary"> comment </v-icon>
                </template>
              </v-textarea>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-btn
                color="red"
                block
                text
                class="btnActions mx-3"
                style="float: right"
                @click="approvalModal = false"
              >
                Cancel
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <div v-if="!approvalModal.status">
                <v-btn
                  color="red"
                  block
                  class="btnActions"
                  style="color: #fff"
                  :disabled="!isValid"
                  @click.prevent="approve(false)"
                >
                  Reject
                  <v-icon class="mx-3">thumb_down_alt</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  color="success"
                  block
                  class="btnActions"
                  style="color: #fff"
                  @click.prevent="
                    editDuration ? validateLeave() : approve(true)
                  "
                >
                  {{ editDuration ? "Validate" : "Approve" }}
                  <v-icon class="mx-3">thumb_up_alt</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
@import "../styles.css";
</style>
<script>
import { mapState, mapActions } from "vuex";
import { SetupMixin } from "../../../mixins";
export default {
  name: "Approve",
  components: {},
  mixins: [SetupMixin],
  data: () => ({
    isValid: false,
    no_days: 0,
    reject: false,
    endDate: "",
    editDuration: false,
    startDate: "",
    selectedType: null,
    comments: "",
    rules: {
      common: {
        required: (v) => !!v || "Field is required.",
      },
    },
  }),
  created() {},
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    ...mapState({
      application: function (state) {
        return state.leave.approvalApplications.filter(
          (app) =>
            app.application_code === state.leave.approvalModal.application_code
        );
      },
    }),
    approvalModal: {
      get() {
        return this.$store.state.leave.approvalModal;
      },
      set(value) {
        this.$store.commit("leave/SET_APPROVAL_MODAL", {
          application_code: "",
          open: value,
          status: false,
        });
      },
    },
  },
  watch: {
    approvalModal() {
      if (this.approvalModal.open) {
        this.startDate = this.application[0].start_date;
        this.endDate = this.application[0].end_date;
        this.no_days = this.application[0].days_applied;
        this.selectedType = "right";
      }
    },
  },
  methods: {
    ...mapActions({
      validateLeave: function (dispatch) {
        const data = {
          leave_type_code: this.application[0].leave_code,
          start_date: this.startDate,
          leave_days: this.no_days,
          end_date: this.endDate,
        };
        dispatch("leave/validateLeave", data);
      },
      approve: function (dispatch, flag) {
        if (flag) {
          const data = {
            application_code: this.approvalModal.application_code,
            approved_start_date: this.startDate,
            approved_days: this.no_days,
          };
          dispatch("leave/approve", data);
        } else {
          const data = {
            application_code: this.approvalModal.application_code,
            comments: this.comments,
          };
          // console.log('cancel data', data)
          dispatch("leave/reject", data);
        }
        this.comments = "";
      },
    }),
  },
};
</script>
