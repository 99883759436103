import call from "@kinetics254/cassandra-base/service/http";
import { ImprestConstants } from "./index";

export default {
  namespaced: true,

  state: {
    imprests: [],
    imprestsDetails: {},
    imprestLinesDetails: [],
    pendingImprests: [],
    expenseCodes: [],
    modeOfTransport: [],
    dimensions: [],
    unitsOfMeasure: [],
    speedKeys: [],
    funds: [],
    imprestTypes: [],
    currencies: [],
    approvalModal: {
      open: false,
      imprest_code: "",
      status: false,
    },
    breadcrumbs: [
      {
        text: "Dashboard",
        href: "/",
        disabled: false,
      },
    ],
    ImprestSurrenderDocument: "",
    imprestDocument: "",
  },

  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    SET_IMPREST: (state, payload) => {
      state.imprests = payload;
    },
    SET_PENDING_IMPRESTS: (state, payload) => {
      state.pendingImprests = payload;
    },
    SET_IMPREST_DETAILS: (state, payload) => {
      state.imprestsDetails = payload;
    },

    SET_EXPENSE_CODES: (state, payload) => {
      state.expenseCodes = payload;
    },
    SET_APPROVAL_MODAL: (state, payload) => {
      // eslint-disable-next-line camelcase
      const { open, imprest_code, approvalEntryNo, status } = payload;
      state.approvalModal = { open, imprest_code, approvalEntryNo, status };
    },
    SET_MODE_OF_TRANSPORT: (state, payload) => {
      state.modeOfTransport = payload;
    },
    SET_BREADCRUMBS: (state, payload) => {
      const breadcrumbs = state.breadcrumbs;
      if (breadcrumbs.find((item) => item.text === payload.name)) {
        breadcrumbs.pop();
        breadcrumbs[breadcrumbs.length - 1].disabled = true;
      } else {
        breadcrumbs[breadcrumbs.length - 1].disabled = false;
        breadcrumbs.push({
          text: payload.name,
          href: payload.path,
          disabled: true,
        });
      }
      state.breadcrumbs = breadcrumbs;
    },
  },

  getters: {
    imprestGetters: (state) => (val) => state[val],
    imprests: (state) => state.imprests,
    imprestLinesDetails: (state) => state.imprestLinesDetails,
    imprestsDetails: (state) => state.imprestsDetails,
    pendingImprests: (state) => state.pendingImprests,
    expenseCodes: (state) => state.expenseCodes,
    breadcrumbs: (state) => state.breadcrumbs,
    modeOfTransport: (state) => state.modeOfTransport,
  },

  actions: {
    /**
     * Save new imprest request
     * @param commit
     * @param dispatch
     * @param data
     */
    saveImprest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.imprest, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully created a new imprest request"
          );
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateImprestCard: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call(data.method, ImprestConstants.imprest, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            data.method === "post"
              ? "You have successfully created a new imprest request"
              : `You have successfully Updated ${res.data.data[0].no} Imprest Record`
          );
          Event.$emit("back");
          dispatch("sendForApproval", { docNo: res.data.data[0].no });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get all imprests
     * @param commit
     */
    getImprests: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.imprest)
        .then((res) => {
          commit("SET_IMPREST", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getImprestLineDetailed: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.saveReceipt)
        .then((res) => {
          commit("SET_EXPENSE_CODES", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get expense codes
     * @param commit
     */
    getExpenseCodes: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.expenseCode)
        .then((res) => {
          commit("SET_EXPENSE_CODES", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get units of measure
     * @param commit
     */
    getUnitsOfMeasure: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.unitsOfMeasure)
        .then((res) => {
          commit("MUTATE", { state: "unitsOfMeasure", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get units of measure
     * @param commit
     */
    getCurrencies: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.currencies)
        .then((res) => {
          commit("MUTATE", { state: "currencies", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get dimension values
     * @param commit
     */
    getDimensionValues: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.dimensions)
        .then((res) => {
          commit("MUTATE", { state: "dimensions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * This method gets a single imprest details
     * @param commit
     * @param data
     */
    getImprestsDetails: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.imprestDetails(btoa(data)))
        .then((res) => {
          commit("SET_IMPREST_DETAILS", res.data.data.shift());
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDetailedImprests: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.detailedImprest(data))
        .then((res) => {
          commit("SET_IMPREST_DETAILS", res.data.data.shift());
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Delete an imprest record
     * @param commit
     * @param dispatch
     * @param data
     */
    deleteImprest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", ImprestConstants.deleteImprest(data), data)
        .then(() => {
          Event.$emit("ApiSuccess", "Imprest successfully deleted");
          Event.$emit("back");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Get all the imprests for the pending approvals
     * @param commit
     */
    getPendingApprovals: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.pendingImprests)
        .then((res) => {
          commit("SET_PENDING_IMPRESTS", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Update an imprest
     * @param commit
     * @param dispatch
     * @param data
     */
    updateImprest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", ImprestConstants.imprest, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Imprest successfully saved");
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getImprestsDetails", data.no);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deleteImprestLine: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", ImprestConstants.imprest, data)
        .then(() => {
          dispatch("getImprests");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deleteImage: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.deleteImage, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          dispatch("getImprestsDetails", data.DetailedLineMgtDocNo);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteAttachment: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.deleteAttachment, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Attachment successfully deleted");
          dispatch("getImprestsDetails", data.no);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getImage: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.getReceipt, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          dispatch(
            "getImprestsDetails",
            res.data.data.shift().DetailedLineMgtDocNo
          );
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    SaveImage: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.saveReceipt, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          dispatch(
            "getImprestsDetails",
            res.data.data.shift().DetailedLineMgtDocNo
          );
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateAccounting: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.updateAccounting, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.message);
          dispatch(
            "getImprestsDetails",
            res.data.data.shift().DetailedLineMgtDocNo
          );
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    // Imprest approvals
    approveImprest: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.approveImprest, data)
        .then((res) => {
          commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
          Event.$emit("ApiSuccess", "Imprest Approved");
          dispatch("getPendingApprovals", { ...data });
          Event.$emit("back");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    RejectImprest: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.RejectImprest, data)
        .then((res) => {
          commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
          Event.$emit("ApiSuccess", "Imprest Rejected");
          dispatch("getPendingApprovals", { ...data });
          Event.$emit("back");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    ReopenDocument: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
      call("post", ImprestConstants.ReopenDocument, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Imprest Approval Request Reopened");
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    cancelApprovalRequest: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
      call("post", ImprestConstants.cancelApprovalRequest, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Imprest Approval Request Cancelled");
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getImprestsDetails", data.docNo);
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("SET_APPROVAL_MODAL", { imprest_code: "", open: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    // settle imprest
    settleImprest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.imprestAccountedFor, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Imprest accounting successfuly submitted");
          Event.$emit("back");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    setBreadCrumbs: ({ commit }, data) => {
      commit("SET_BREADCRUMBS", data);
    },

    /** send imprest for approval */
    sendForApproval: ({ commit, dispatch }, data) => {
      call("post", ImprestConstants.sendForApproval, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Imprest successfully sent for approval");
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getImprestsDetails", data.docNo);
        })
        .catch((error) => {
          Event.$emit(
            "ApiError",
            `Unable to send Imprest Record For Approval: ${error.response.data.message}`
          );
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getModeOfTransport: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.modeOfTransport)
        .then((res) => {
          commit("SET_MODE_OF_TRANSPORT", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getfunds: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.funds)
        .then((res) => {
          commit("MUTATE", { state: "funds", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getdimensionSpeedKey: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.dimensionSpeedKey)
        .then((res) => {
          commit("MUTATE", { state: "speedKeys", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getImprestTypeApi: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", ImprestConstants.imprestTypeApi)
        .then((res) => {
          commit("MUTATE", { state: "imprestTypes", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    CheckBudget: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.CheckBudget, data)
        .then((res) => {
          Event.$emit("ApiSuccess", res.data.data[0]);
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getImprestsDetails", data.documentNo);
        })
        .catch((error) => {
          Event.$emit("ApiInfo", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteExpenseItem: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", ImprestConstants.expenseItem, { data })
        .then(() => {
          Event.$emit("ApiSuccess", "Item successfully removed");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getImprestSurrenderDocument: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.ImprestSurrenderDocument, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "ImprestSurrenderDocument",
            data: process.env.VUE_APP_FILE_API + res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getImprestDocument: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", ImprestConstants.imprestDocument, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "imprestDocument",
            data: process.env.VUE_APP_FILE_API + res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
