<template>
  <v-container>
    <v-card class="my-5 elevation-0" height="100%">
      <v-card-title>
        <v-btn text small @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
        <v-spacer />
        <v-btn small color="primary" @click="downloadLink(base64String)">
          <v-icon left color="white"> mdi-download </v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-divider />
      <!-- {{ statement }} -->
      <v-row>
        <v-col cols="8" offset="2">
          <v-card-text
            class="elevation-1 mb-1"
            v-for="i in pageCount(base64String)"
            :key="i"
          >
            <pdf
              style="width: 100%"
              :page="i"
              :src="`data:application/pdf;base64,${base64String}`"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import pdf from "vue-pdf";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "EmployeeStatement",
  mixins: [FileMixin],
  components: { pdf },
  data() {
    return {
      numPages: undefined,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Approvals/getStatement", v.authUser.employee);
    });
  },
  computed: {
    authUser() {
      return AuthService.user;
    },
    statement() {
      return this.$store.getters["Approvals/approvalGetters"]("statement");
    },
    base64String(){
        return this.statement? this.statement.statementB64: "";
    }
  },
  methods: {
    pageCount: function (value) {
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
</script>
